@import url(https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/static/pretendard.css);
html,
body,
head,
table,
thead,
tbody,
tr,
td,
th,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
ul,
ol,
li,
span,
a,
em,
br {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

ol,
ul,
li {
  list-style: none;
}

html,
body {
  scroll-behavior: smooth;
}

* {
  font-family: "Pretendard";
}

#app-container {
  overflow-x: hidden;
}

.main-area {
  width: 100vw;
  min-width: 1120px;
  min-height: 100vh;
}
@media (max-width: 768px) {
  .main-area {
    min-width: 0;
    min-width: initial;
  }
}

a {
  text-decoration: none;
}

.inner-container {
  max-width: 1120px;
  margin: 0 auto;
  padding: 100px 0px;
}
@media (max-width: 1120px) {
  .inner-container {
    padding: 100px 16px;
  }
}

.clearfix {
  overflow: hidden;
  clear: both;
  height: 0px;
}

.flex-center-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.float {
  float: left;
}

@media (max-width: 768px) {
  .float {
    float: none;
    margin-top: 30px;
  }
  .flex-center-container {
    display: block;
  }
}
input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
input::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.ant-input-suffix {
  z-index: 0 !important;
}

.modal-inner-container {
  z-index: 10 !important;
  background-color: white;
  border-radius: 4px;
  padding: 28px 40px;
  width: 50%;
  min-width: 370px;
  position: relative;
  overflow: hidden;
}
.modal-inner-container .modal-title {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 8px;
}
.modal-inner-container .modal-body {
  font-size: 14px;
  letter-spacing: -0.3px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 40px;
}
.modal-inner-container .modal-button {
  width: 100%;
  height: 48px;
  background-color: #606060;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
}
.modal-inner-container .close-button {
  display: inline-block;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}
.modal-inner-container .close-button img {
  width: 100%;
}

#loading {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
}

.ant-table-wrapper {
  background-color: white;
  border-radius: 4px;
}
.ant-table-wrapper .ant-table-thead {
  background-color: white;
}
.ant-table-wrapper .ant-table-thead tr > th {
  background-color: white;
  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.7);
}
.ant-table-wrapper .ant-table-tbody > tr > td .button {
  border: 0px;
}
.ant-table-wrapper .ant-table-tbody > tr > td .button span {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
}
.ant-table-wrapper .ant-table-tbody > tr > td span {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.3px;
  color: rgba(0, 0, 0, 0.7);
}
.ant-table-wrapper .ant-pagination.ant-table-pagination {
  margin-right: 20px;
}

.ant-popover-inner-content {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  background-color: #3bc1ff;
  padding: 24px !important;
  border-radius: 12px;
}
.ant-popover-inner-content p {
  cursor: pointer;
  font-size: 12px;
  color: white;
  padding: 6px 12px;
}
.ant-popover-inner-content p:hover {
  color: #e6e6e6;
  transition: all 0.1s;
}
.ant-popover-inner-content p.price-title {
  padding: 0px 0px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32x;
  letter-spacing: -0.6px;
  color: #ffffff;
}
.ant-popover-inner-content p.price-title:hover {
  opacity: 1;
}
.ant-popover-inner-content p.price-desc {
  margin-top: 20.5px;
  padding: 0px 0px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.3px;
  color: #ffffff;
}
.ant-popover-inner-content p.price-desc:hover {
  opacity: 1;
}

.ant-popover-arrow {
  border-top-color: rgba(0, 0, 0, 0.75) !important;
  border-left-color: rgba(0, 0, 0, 0.75) !important;
}

.ant-modal-content {
  padding: 32px;
  min-width: 340px;
  width: 80%;
  max-height: 80vh;
  overflow-y: scroll;
}
@media (max-width: 768px) {
  .ant-modal-content {
    width: 100%;
  }
}
.ant-modal-content .ant-modal-header {
  border-bottom: 0px;
  margin-bottom: 8px;
}
.ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 28px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.7);
}
.ant-modal-content .ant-modal-body {
  padding: 0px 0px;
}
.ant-modal-content .ant-modal-body .service {
  margin-bottom: 20px;
}
.ant-modal-content .ant-modal-body .service p.title {
  font-size: 12px;
  font-weight: bold;
  color: #a7a7a7;
  margin-bottom: 8px;
}
.ant-modal-content .ant-modal-body .service .search-button {
  position: absolute;
  top: 1px;
  right: 1px;
  background-color: #ececec;
  height: 34px;
  width: 50px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 12px;
  line-height: 34px;
  text-align: center;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
}
.ant-modal-content .ant-modal-body .service .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0px;
}
.ant-modal-content .ant-modal-body .service .ant-collapse-borderless > .ant-collapse-item {
  border: 0px;
}
.ant-modal-content .ant-modal-body .service .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
  padding-top: 4px;
  padding-bottom: 12px;
}
.ant-modal-content .ant-modal-body .service .time-checker {
  width: calc(50% - 4px);
}
.ant-modal-content .ant-modal-body .service .time-checker.margin {
  margin-right: 8px;
}
.ant-modal-content .ant-modal-body .service input {
  height: 36px;
}
.ant-modal-content .ant-modal-body .service .name-wrapper {
  position: relative;
}
.ant-modal-content .ant-modal-body .service .name-wrapper .sex-type-wrapper {
  position: absolute;
  top: 1px;
  right: 1px;
}
.ant-modal-content .ant-modal-body .service .name-wrapper .sex-type-wrapper .sex-type {
  display: inline-block;
  width: 34px;
  height: 34px;
  line-height: 36px;
  text-align: center;
  border-left: 1px solid #e6e6e6;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.2px;
  color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.ant-modal-content .ant-modal-body .service .name-wrapper .sex-type-wrapper .sex-type:hover {
  color: rgba(0, 0, 0, 0.3);
  transition: all 0.3s;
}
.ant-modal-content .ant-modal-body .service .name-wrapper .sex-type-wrapper .sex-type.active {
  color: #3bc1ff;
}
.ant-modal-content .ant-modal-body .service .select {
  display: inline-block;
  height: 36px;
  width: 100%;
}
.ant-modal-content .ant-modal-body .service .select .ant-select-selection--single {
  height: 100%;
}
.ant-modal-content .ant-modal-body .service .select .ant-select-selection__rendered {
  line-height: 36px;
}
.ant-modal-content .ant-modal-body .service .ant-input-number {
  width: 100%;
  height: 48px;
}
.ant-modal-content .ant-modal-body .service .ant-input-number .ant-input-number-input-wrap {
  height: 100%;
}
.ant-modal-content .ant-modal-body .service .ant-input-number .ant-input-number-input-wrap .ant-input-number-input {
  height: 100%;
}
.ant-modal-content .ant-modal-body .service .ant-input-number .ant-input-number-input-wrap input {
  height: 100%;
}
.ant-modal-content .ant-modal-body .service .ant-input-number .ant-input-number-handler-wrap {
  opacity: 1;
  width: 48px;
}
.ant-modal-content .ant-modal-body .service .ant-input-number .ant-input-number-handler-wrap span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-modal-content .ant-modal-body .service .ant-input-number .ant-input-number-handler-wrap span i {
  position: relative;
  margin-top: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  font-size: 16px;
}
.ant-modal-content .ant-modal-body .service .ant-input-number .ant-input-number-handler-wrap .anticon.anticon-up.ant-input-number-handler-up-inner {
  color: #3bc1ff;
}
.ant-modal-content .ant-modal-body .service .ant-input-number .ant-input-number-handler-wrap .anticon.anticon-down.ant-input-number-handler-down-inner {
  color: #3bc1ff;
}
.ant-modal-content .ant-modal-body .mate-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 124px;
  margin-top: 40px;
  margin-bottom: 26px;
}
.ant-modal-content .ant-modal-body .mate-img-wrapper img {
  width: 124px;
  height: 124px;
  object-fit: cover;
  border-radius: 50%;
}
.ant-modal-content .ant-modal-body .mate-info-wrapper > div {
  margin-bottom: 16px;
}
.ant-modal-content .ant-modal-body .mate-info-wrapper > div:last-childd(1) {
  margin-bottom: 0px;
}
.ant-modal-content .ant-modal-body .mate-info-wrapper .label {
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.3px;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 4px;
}
.ant-modal-content .ant-modal-body .mate-info-wrapper .input {
  width: 100%;
  height: 36px;
  line-height: 36px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  cursor: not-allowed;
}
.ant-modal-content .ant-modal-body .mate-info-wrapper .input .value {
  text-indent: 12px;
  font-size: 12px;
  color: #b6b6b6;
}
.ant-modal-content .ant-modal-body .mate-info-wrapper button {
  width: 100%;
  margin-top: 24px;
  height: 48px;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.3px;
  color: rgba(0, 0, 0, 0.7);
}

.ant-notification-notice-message {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 12px !important;
}

.swal2-title {
  word-break: keep-all !important;
}

@media (max-width: 768px) {
  .swal2-title {
    font-size: 26px !important;
  }
  #swal2-content > div {
    font-size: 16px;
  }
  .swal2-confirm {
    width: 50% !important;
  }
  .swal2-header {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .swal2-content {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 51;
}

.ant-select-selection.ant-select-selection--single {
  height: 100%;
  width: 100%;
}

.ant-select-selection-selected-value {
  font-size: 16px;
}

.ant-select-selection--single .ant-select-selection__rendered {
  line-height: 44px;
}

.ant-pagination {
  margin-top: 32px !important;
  text-align: center !important;
}

.ant-timeline-item-label {
  width: 70px !important;
  text-align: center !important;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.15px;
  color: #3bc1ff;
  top: -5px !important;
}

.ant-timeline-item-head {
  left: 69px !important;
}

.ant-timeline-item-content {
  left: 62px !important;
  color: rgba(0, 0, 0, 0.7);
  font-size: 13px;
  top: -5px !important;
}
@media (max-width: 768px) {
  .ant-timeline-item-content {
    width: calc(100% - 72px) !important;
    word-break: keep-all !important;
  }
}

.ant-timeline-item-tail {
  left: 69px !important;
}

.ant-pagination-options {
  display: none;
}
