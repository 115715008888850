html,
body,
head,
table,
thead,
tbody,
tr,
td,
th,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
ul,
ol,
li,
span,
a,
em,
br {
    margin: 0px;
    padding: 0px;

    box-sizing: border-box;
}

ol,
ul,
li {
    list-style: none;
}

html,
body {
    scroll-behavior: smooth;
}

@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/static/pretendard.css');
* {
    font-family: 'Pretendard';
    //   color: #000000;
}

#app-container {
    // display: flex;
    // flex-direction: row;
    overflow-x: hidden;
}

.main-area {
    //// sidebar options
    // flex: 1;
    width: 100vw;
    min-width: 1120px;
    min-height: 100vh;

    @media (max-width: 768px) {
        min-width: initial;
    }
}

a {
    text-decoration: none;
}

.inner-container {
    max-width: 1120px;
    margin: 0 auto;
    padding: 100px 0px;

    @media (max-width: 1120px) {
        padding: 100px 16px;
    }
}

.clearfix {
    overflow: hidden;
    clear: both;
    height: 0px;
}

.flex-center-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.float {
    float: left;
}

@media (max-width: 768px) {
    .float {
        float: none;
        margin-top: 30px;
    }

    .flex-center-container {
        display: block;
    }

    // br {
    //     display: none;
    //     &.mobile-exist {
    //         display: block;
    //     }
    // }
}

input::placeholder {
    color: rgba(0, 0, 0, 0.3);
}

.ant-input-suffix {
    z-index: 0 !important;
}

.modal-inner-container {
    z-index: 10 !important;
    background-color: white;
    border-radius: 4px;
    padding: 28px 40px;
    width: 50%;
    min-width: 370px;
    // max-width: 430px;
    position: relative;
    overflow: hidden;

    .modal-title {
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 8px;
    }

    .modal-body {
        font-size: 14px;
        letter-spacing: -0.3px;
        text-align: center;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 40px;
    }

    .modal-button {
        width: 100%;
        height: 48px;
        background-color: #606060;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
    }

    .close-button {
        display: inline-block;
        position: absolute;
        top: 16px;
        right: 16px;
        width: 14px;
        height: 14px;
        cursor: pointer;

        img {
            width: 100%;
        }
    }
}

#loading {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
}

.ant-table-wrapper {
    background-color: white;
    border-radius: 4px;

    .ant-table-thead {
        background-color: white;

        tr > th {
            background-color: white;
            font-size: 14px;
            font-weight: bold;

            color: rgba(0, 0, 0, 0.7);
        }
    }

    .ant-table-tbody > tr > td .button {
        border: 0px;

        span {
            font-size: 12px;
            font-weight: bold;
            text-align: center;
            color: #ffffff;
        }
    }

    .ant-table-tbody > tr > td span {
        font-size: 14px;
        font-weight: normal;
        letter-spacing: -0.3px;
        color: rgba(0, 0, 0, 0.7);
    }

    .ant-pagination.ant-table-pagination {
        margin-right: 20px;
    }
}

.ant-popover-inner-content {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    // background-color: rgba(0, 0, 0, 0.75);
    background-color: #3bc1ff;
    padding: 24px !important;
    border-radius: 12px;

    p {
        cursor: pointer;
        font-size: 12px;
        color: white;
        padding: 6px 12px;

        &:hover {
            color: #e6e6e6;
            transition: all 0.1s;
        }
    }

    p.price-title {
        padding: 0px 0px;

        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 32x;

        letter-spacing: -0.6px;
        color: #ffffff;

        &:hover {
            opacity: 1;
        }
    }

    p.price-desc {
        margin-top: 20.5px;
        padding: 0px 0px;

        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;

        letter-spacing: -0.3px;
        color: #ffffff;

        &:hover {
            opacity: 1;
        }
    }
}

.ant-popover-arrow {
    // box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15) !important;
    // background-color: rgba(0, 0, 0, 0.75) !important;
    // border-color: rgba(0, 0, 0, 0.75) !important;
    border-top-color: rgba(0, 0, 0, 0.75) !important;
    border-left-color: rgba(0, 0, 0, 0.75) !important;
}

.ant-modal-content {
    padding: 32px;
    min-width: 340px;
    width: 80%;
    max-height: 80vh;
    overflow-y: scroll;

    @media (max-width: 768px) {
        width: 100%;
    }

    .ant-modal-header {
        border-bottom: 0px;
        margin-bottom: 8px;

        .ant-modal-title {
            font-size: 28px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.7);
        }
    }

    .ant-modal-body {
        padding: 0px 0px;

        .service {
            margin-bottom: 20px;

            p.title {
                font-size: 12px;
                font-weight: bold;
                color: #a7a7a7;
                margin-bottom: 8px;
            }

            .search-button {
                position: absolute;
                top: 1px;
                right: 1px;
                background-color: #ececec;
                height: 34px;
                width: 50px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;

                font-size: 12px;
                line-height: 34px;
                text-align: center;
                cursor: pointer;
                color: rgba(0, 0, 0, 0.4);
            }

            .ant-collapse > .ant-collapse-item > .ant-collapse-header {
                padding: 0px;
            }

            .ant-collapse-borderless > .ant-collapse-item {
                border: 0px;
            }

            .ant-collapse-content > .ant-collapse-content-box {
                padding: 0px;
                padding-top: 4px;
                padding-bottom: 12px;
            }

            .time-checker {
                width: calc(50% - 4px);
            }

            .time-checker.margin {
                margin-right: 8px;
            }

            input {
                height: 36px;
            }

            .name-wrapper {
                position: relative;

                .sex-type-wrapper {
                    position: absolute;
                    top: 1px;
                    right: 1px;

                    .sex-type {
                        display: inline-block;
                        width: 34px;
                        height: 34px;
                        line-height: 36px;
                        text-align: center;
                        border-left: 1px solid #e6e6e6;

                        font-size: 14px;
                        font-weight: normal;
                        letter-spacing: -0.2px;
                        color: rgba(0, 0, 0, 0.2);
                        cursor: pointer;

                        &:hover {
                            color: rgba(0, 0, 0, 0.3);
                            transition: all 0.3s;
                        }

                        &.active {
                            color: #3bc1ff;
                        }
                    }
                }
            }

            .select {
                display: inline-block;
                height: 36px;
                width: 100%;

                .ant-select-selection--single {
                    height: 100%;
                }

                .ant-select-selection__rendered {
                    line-height: 36px;
                }
            }

            .ant-input-number {
                width: 100%;
                height: 48px;

                .ant-input-number-input-wrap {
                    height: 100%;

                    .ant-input-number-input {
                        height: 100%;
                    }

                    input {
                        height: 100%;
                    }
                }

                .ant-input-number-handler-wrap {
                    opacity: 1;
                    width: 48px;

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        i {
                            position: relative;
                            margin-top: 0px;
                            left: 0px;
                            top: 0px;
                            bottom: 0px;
                            right: 0px;
                            font-size: 16px;
                        }
                    }
                    .anticon.anticon-up.ant-input-number-handler-up-inner {
                        color: #3bc1ff;
                    }
                    .anticon.anticon-down.ant-input-number-handler-down-inner {
                        color: #3bc1ff;
                    }
                }
            }
        }

        .mate-img-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 124px;
            margin-top: 40px;
            margin-bottom: 26px;

            img {
                width: 124px;
                height: 124px;
                object-fit: cover;
                border-radius: 50%;
            }
        }

        .mate-info-wrapper {
            > div {
                margin-bottom: 16px;
            }
            > div:last-childd(1) {
                margin-bottom: 0px;
            }

            .label {
                font-size: 12px;
                font-weight: normal;
                letter-spacing: -0.3px;
                color: rgba(0, 0, 0, 0.7);
                margin-bottom: 4px;
            }

            .input {
                width: 100%;
                height: 36px;
                line-height: 36px;
                border-radius: 4px;
                border: 1px solid #e6e6e6;
                cursor: not-allowed;

                .value {
                    text-indent: 12px;
                    font-size: 12px;
                    // font-weight: bold;
                    color: #b6b6b6;
                }
            }

            button {
                width: 100%;
                margin-top: 24px;
                height: 48px;
            }
        }
    }
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.3px;
    color: rgba(0, 0, 0, 0.7);
}

.ant-notification-notice-message {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 12px !important;
}

.swal2-title {
    word-break: keep-all !important;
}

@media (max-width: 768px) {
    .swal2-title {
        font-size: 26px !important;
    }

    #swal2-content > div {
        font-size: 16px;
    }

    .swal2-confirm {
        width: 50% !important;
    }

    .swal2-header {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }

    .swal2-content {
        padding-left: 4px !important;
        padding-right: 4px !important;
    }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 51;
}

.ant-select-selection.ant-select-selection--single {
    height: 100%;
    width: 100%;
}

.ant-select-selection-selected-value {
    font-size: 16px;
}

.ant-select-selection--single .ant-select-selection__rendered {
    line-height: 44px;
}
.ant-pagination {
    margin-top: 32px !important;
    text-align: center !important;
}

.ant-timeline-item-label {
    width: 70px !important;
    text-align: center !important;

    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.15px;
    color: #3bc1ff;

    top: -5px !important;
}

.ant-timeline-item-head {
    left: 69px !important;
}

.ant-timeline-item-content {
    left: 62px !important;
    color: rgba(0, 0, 0, 0.7);
    font-size: 13px;

    top: -5px !important;

    @media (max-width: 768px) {
        width: calc(100% - 72px) !important;
        word-break: keep-all !important;
    }
}

.ant-timeline-item-tail {
    left: 69px !important;
}

.ant-pagination-options {
    display: none;
}
